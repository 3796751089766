/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import {createFileRoute} from '@tanstack/react-router';

// Import Routes

import {Route as rootRoute} from './routes/__root';
import {Route as UnauthorizedImport} from './routes/unauthorized';
import {Route as AuthImport} from './routes/_auth';
import {Route as AuthSectionIndexImport} from './routes/_auth.$section/index';
import {Route as AuthSectionIdImport} from './routes/_auth.$section/$id';

// Create Virtual Routes

const AuthIndexLazyImport = createFileRoute('/_auth/')();

// Create/Update Routes

const UnauthorizedRoute = UnauthorizedImport.update({
    id: '/unauthorized',
    path: '/unauthorized',
    getParentRoute: () => rootRoute,
} as any);

const AuthRoute = AuthImport.update({
    id: '/_auth',
    getParentRoute: () => rootRoute,
} as any);

const AuthIndexLazyRoute = AuthIndexLazyImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AuthRoute,
} as any).lazy(() => import('./routes/_auth.index.lazy').then((d) => d.Route));

const AuthSectionIndexRoute = AuthSectionIndexImport.update({
    id: '/$section/',
    path: '/$section/',
    getParentRoute: () => AuthRoute,
} as any);

const AuthSectionIdRoute = AuthSectionIdImport.update({
    id: '/$section/$id',
    path: '/$section/$id',
    getParentRoute: () => AuthRoute,
} as any);

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
    interface FileRoutesByPath {
        '/_auth': {
            id: '/_auth';
            path: '';
            fullPath: '';
            preLoaderRoute: typeof AuthImport;
            parentRoute: typeof rootRoute;
        };
        '/unauthorized': {
            id: '/unauthorized';
            path: '/unauthorized';
            fullPath: '/unauthorized';
            preLoaderRoute: typeof UnauthorizedImport;
            parentRoute: typeof rootRoute;
        };
        '/_auth/': {
            id: '/_auth/';
            path: '/';
            fullPath: '/';
            preLoaderRoute: typeof AuthIndexLazyImport;
            parentRoute: typeof AuthImport;
        };
        '/_auth/$section/$id': {
            id: '/_auth/$section/$id';
            path: '/$section/$id';
            fullPath: '/$section/$id';
            preLoaderRoute: typeof AuthSectionIdImport;
            parentRoute: typeof AuthImport;
        };
        '/_auth/$section/': {
            id: '/_auth/$section/';
            path: '/$section';
            fullPath: '/$section';
            preLoaderRoute: typeof AuthSectionIndexImport;
            parentRoute: typeof AuthImport;
        };
    }
}

// Create and export the route tree

interface AuthRouteChildren {
    AuthIndexLazyRoute: typeof AuthIndexLazyRoute;
    AuthSectionIdRoute: typeof AuthSectionIdRoute;
    AuthSectionIndexRoute: typeof AuthSectionIndexRoute;
}

const AuthRouteChildren: AuthRouteChildren = {
    AuthIndexLazyRoute: AuthIndexLazyRoute,
    AuthSectionIdRoute: AuthSectionIdRoute,
    AuthSectionIndexRoute: AuthSectionIndexRoute,
};

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren);

export interface FileRoutesByFullPath {
    '': typeof AuthRouteWithChildren;
    '/unauthorized': typeof UnauthorizedRoute;
    '/': typeof AuthIndexLazyRoute;
    '/$section/$id': typeof AuthSectionIdRoute;
    '/$section': typeof AuthSectionIndexRoute;
}

export interface FileRoutesByTo {
    '/unauthorized': typeof UnauthorizedRoute;
    '/': typeof AuthIndexLazyRoute;
    '/$section/$id': typeof AuthSectionIdRoute;
    '/$section': typeof AuthSectionIndexRoute;
}

export interface FileRoutesById {
    __root__: typeof rootRoute;
    '/_auth': typeof AuthRouteWithChildren;
    '/unauthorized': typeof UnauthorizedRoute;
    '/_auth/': typeof AuthIndexLazyRoute;
    '/_auth/$section/$id': typeof AuthSectionIdRoute;
    '/_auth/$section/': typeof AuthSectionIndexRoute;
}

export interface FileRouteTypes {
    fileRoutesByFullPath: FileRoutesByFullPath;
    fullPaths: '' | '/unauthorized' | '/' | '/$section/$id' | '/$section';
    fileRoutesByTo: FileRoutesByTo;
    to: '/unauthorized' | '/' | '/$section/$id' | '/$section';
    id: '__root__' | '/_auth' | '/unauthorized' | '/_auth/' | '/_auth/$section/$id' | '/_auth/$section/';
    fileRoutesById: FileRoutesById;
}

export interface RootRouteChildren {
    AuthRoute: typeof AuthRouteWithChildren;
    UnauthorizedRoute: typeof UnauthorizedRoute;
}

const rootRouteChildren: RootRouteChildren = {
    AuthRoute: AuthRouteWithChildren,
    UnauthorizedRoute: UnauthorizedRoute,
};

export const routeTree = rootRoute._addFileChildren(rootRouteChildren)._addFileTypes<FileRouteTypes>();

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_auth",
        "/unauthorized"
      ]
    },
    "/_auth": {
      "filePath": "_auth.tsx",
      "children": [
        "/_auth/",
        "/_auth/$section/$id",
        "/_auth/$section/"
      ]
    },
    "/unauthorized": {
      "filePath": "unauthorized.tsx"
    },
    "/_auth/": {
      "filePath": "_auth.index.lazy.tsx",
      "parent": "/_auth"
    },
    "/_auth/$section/$id": {
      "filePath": "_auth.$section/$id.tsx",
      "parent": "/_auth"
    },
    "/_auth/$section/": {
      "filePath": "_auth.$section/index.tsx",
      "parent": "/_auth"
    }
  }
}
ROUTE_MANIFEST_END */
