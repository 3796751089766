---
title: 'Command Line Interface'
metaTitle: 'Admin-UI Docs | Getting started > Command Line Interface'
metaDescription: 'Learn about more about our different commands in the Admin-UI project'
---

We use [pnpm](https://pnpm.io/) as the package manager for our project.
It's essential to use it to install dependencies with [**pnpm install**](https://pnpm.io/cli/install) command before launches the various commands.

## The commands in the project root

You can learn a lot more [about the different PNPM commands in their documentation](https://pnpm.io/pnpm-cli).

- `pnpm --recursive <command> --filter '<package-name>'` allows to execute a npm script only in scoped packages.
- `<command>` can be any npm script specified in the `package.json` file of the scoped packages
- `<package-name>` is the name of any package under the `packages` folder, it can be repeated to run the command in more than one folder.
- for example for build: `pnpm --recursive build --filter @core/configuration`
- for example for tests: `pnpm --recursive test:watch --filter @core/configuration`

### Start (root command)

Is the command to start the project Admin-UI.

It will build all the packages with `pnpm build` and `pnpm type-check`, which is required for Intellisense (code completion) to work properly.

> Note: Are you changing branch of work? You can just run its commands there `pnpm install && pnpm start`. No need to delete the different `node_modules` folders.

**Usage**

```bash
# launches command prompt to help you get started
pnpm start

# or launch the desired packages directly without the command prompt
pnpm start <@package/name> <@package/name> ...
```

**Options**

| Options          | Explanation                                          |
| ---------------- | ---------------------------------------------------- |
| --no-prompt      | Do not run the prompt when launching the command     |
| --no-type-builds | Do not run the type build when launching the command |

**Variations**

| Sub-commands         | Explanation                                                                        |
| -------------------- | ---------------------------------------------------------------------------------- |
| start:storybook      | launch the [storybook](/guides/10-using-storybook) application to view our stories |

### Build (root command)

Launches the construction of all the packages of the project.

**Usage**

```bash
pnpm build
```

> Note: This command is configured to be run with [Vercel's Turbo library](https://turbo.build/repo/docs).
> This command can therefore [use the various options of the Turbo run command](https://turbo.build/repo/docs/reference/run).

### Type Check (root command)

Launches the **type check** of all the packages which have the `type-check` command in their `package.json` file.

**Usage**

```bash
pnpm type-check
```

> Note: This command is configured to be run with [Vercel's Turbo library](https://turbo.build/repo/docs).
> This command can therefore [use the various options of the Turbo run command](https://turbo.build/repo/docs/reference/run).

### Clean

Will remove all installed dependencies for the packages in the `app`, `components`, `configurations`, `core`, `packages` & `pages` folders.

**Usage**

```bash
pnpm clean
```

**Variations**

| Sub-commands         | Explanation                                                        |
| -------------------- | ------------------------------------------------------------------ |
| clean:apps           | Remove all installed dependencies from **apps** packages           |
| clean:components     | Remove all installed dependencies from **components** packages     |
| clean:configurations | Remove all installed dependencies from **configurations** packages |
| clean:core           | Remove all installed dependencies from **core** packages           |
| clean:package        | Remove all installed dependencies from **package** packages        |
| clean:pages          | Remove all installed dependencies from **pages** packages          |

### Reconstruct

Will remove all installed dependencies and reinstall them without discarding any pending changes.
Run this if you think your current state is corrupted. (this command starts the `clean` command first).

**Usage**

```bash
pnpm reconstruct
```

### Lint (root command)

Launches the **linter** of all the packages, even the root project, in **dry run mode** of all the packages which have the `lint` command in their `package.json` file.

**Usage**

```bash
pnpm lint
```

**Variations**

| Sub-commands | Explanation                                                                                                                                                            |
| ------------ | ---------------------------------------------------------------------------------------------------------------------------------------------------------------------- |
| lint:fix     | launches the **linter** of all the packages, even the root project, in **fix mode** of all the packages which have the `lint:fix` command in their `package.json` file |

> Note: This command is configured to be run with [Vercel's Turbo library](https://turbo.build/repo/docs).
> This command can therefore [use the various options of the Turbo run command](https://turbo.build/repo/docs/reference/run).

### Test (root command)

Launches the **tests** of all the packages which have the `test` command in their `package.json` file.

**Usage**

```bash
pnpm test
```

**Variations**

| Sub-commands | Explanation                                                                                              |
| ------------ | -------------------------------------------------------------------------------------------------------- |
| test:ci      | Launches the **tests** of all the packages which have the `test:ci` command in their `package.json` file |

> Note: This command is configured to be run with [Vercel's Turbo library](https://turbo.build/repo/docs).
> This command can therefore [use the various options of the Turbo run command](https://turbo.build/repo/docs/reference/run).

### Link Package

Launches the [**script to link our external libraries**](/getting-started/07-external-dependencies#running-admin-ui-with-other-packages-that-we-maintain) ([Plasma](https://github.com/coveo/plasma) or [Platform Client](https://github.com/coveo/platform-client)) to this project.

**Usage**

```bash
pnpm link:package
```

**Variations**

| Sub-commands         | Explanation                                                                                                     |
| -------------------- | --------------------------------------------------------------------------------------------------------------- |
| link:plasma          | Launches the script to link [Plasma](https://github.com/coveo/plasma) library to this project                   |
| link:platform-client | Launches the script to link [Platform Client](https://github.com/coveo/platform-client) library to this project |

### Update

Various update commands.

If you would like to find out more about updating our various dependencies through the Admin-UI project, we discuss this in greater detail in [External dependencies](/getting-started/07-external-dependencies) page.

**Variations**

| Sub-commands           | Explanation                                                                                                                                             |
| ---------------------- | ------------------------------------------------------------------------------------------------------------------------------------------------------- |
| update:lockfiles       | Starts updating the [`pnpm-lock.yaml` file](https://pnpm.io/git#lockfiles) with the latest version of each project dependency                           |
| update:plasma          | Update to the latest version of the [Plasma](https://github.com/coveo/plasma) library to this project                                                   |
| update:platform-client | Update to the latest version of the [Platform Client](https://github.com/coveo/platform-client) library to this project                                 |
| update:rd-teams        | Starts updating [the list of teams owning the code](https://github.com/coveo-platform/admin-ui/tree/master/core/debug/src/data) of our Admin-UI project |

### Make

Launches the **package creation script** to create of kind `core`, `components` or `pages` package.

**Usage**

```bash
pnpm link:package
```

## The commands in packages

### Start

Is the command to start your package.

This command is usually issued by the project's `start` command when your package is chosen to be launched.

> Note: This command can be run after the `start` command at the root of the project in a non-selected package. It allows you to hot-start a package.

**Usage**

```bash
pnpm start
```

### Build

Starts string generation and compilation of typescript files in your package.

This command is usually launched by the project's `start` command for each package.

**Usage**

```bash
pnpm build
```

### Type Check

Launches the `type-check` command in your package, which is required for Intellisense (code completion) to work properly.

**Usage**

```bash
pnpm type-check
```

### Lint

Launches the `lint` command in your package in **dry run mode**.

**Usage**

```bash
pnpm lint
```

**Variations**

| Sub-commands  | Explanation                                                                           |
| ------------- | ------------------------------------------------------------------------------------- |
| lint:fix      | launches the **linter** command in your package in **fix mode**                       |
| lint:eslint   | launches the **eslint** command in your package without configuring file extensions   |
| lint:prettier | launches the **prettier** command in your package without configuring file extensions |

### Test

Launches the `test` command in your package.

**Usage**

```bash
pnpm test
```

**Variations**

| Sub-commands | Explanation                                                                      |
| ------------ | -------------------------------------------------------------------------------- |
| test:ci      | Prevent tests from printing messages through the console                         |
| test:debug   | Watch files for changes and rerun all tests when something changes in debug mode |
| test:watch   | Watch files for changes and rerun all tests when something changes               |

> Note: These commands are configured to be executed with [Jest](https://jestjs.io/).
> These commands can therefore [use the various options provided in Jest](https://jestjs.io/docs/cli#options).
